import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "WHY DO KAYAN WOMEN WEAR RINGS?",
      description:
        "Kayan women are unsure why they wear neck rings.Some think it's for protection from tiger attacks,others think it's to prevent rival tribes from abducting them,but it's more likely for beauty and pride.",
      imgUrl: projImg1,
    },
    {
      title: "WHY KAYAN SO SPECIAL?",
      description:
        "Kayan women wear neck rings to lengthen their necks and are known for this.In their tradition, girls start to wear rings when they are around 5 years old.The coils are seldom removed.",
      imgUrl: projImg2,
    },
    {
      title: "WHAT DOES WOMEN WEAR RINGS HURT?",
      description:
        "Tradition is being upheld,but blurred as women live in gift shops using their tradition to make money for outsiders.The rings, weighing up to 20 kg,strain the body and push down collar bones.",
      imgUrl: projImg3,
    },
    {
      title: "WHAT IS THE STATUS OF KAYAN?",
      description:
        "Tourists visit North Thailand to see the 'giraffe women' and buy artisan crafts.The tradition is a source of income for local governments,but Kayan people are not allowed to leave or obtain Thai IDs as Burmese refugees.They lack access to amenities like water, electricity, and healthcare,and cannot afford hospital visits without health insurance.Local schools only offer education up to 6th grade.",
      imgUrl: projImg4,
    },
    {
      title: "WHAT HAPPENEDTO KAYAN?",
      description:
        "Myanmar conflict caused Kayah State residents to flee.Kayan people fled to Thailand as economic migrants,not refugees. They live in guarded villages as tourist attractions and lack citizenship,limiting access to amenities.They moved on their own to flee conflict but are considered refugees in Thailand.All Kayan villages in Thailand are de facto refugee camps.",
      imgUrl: projImg5,
    },
    {
      title: "WHAT IS KAYAN?",
      description:
        "Kayan is a minority group that fled civil war in Myanmar and settled in Northern Thailand.They are a sub-group of the Red Karen,a Tibeto-Burman ethnic group in Myanmar.The women of the Kayan tribe wear brass neck coils that make it appear as if their heads are floating on a gold pedestal. ",
      imgUrl: projImg6,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "" : "animate__animated animate__fadeIn"
                  }
                >
                  <h2>Kayan</h2>
                  <p>
                    Kayan is a minority group that fled civil war in Myanmar and
                    settled in Northern Thailand. <br />
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav> */}
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="second">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane> */}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
