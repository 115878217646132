import meter1 from "../assets/img/meter1.png";
import meter2 from "../assets/img/meter2.png";
import meter3 from "../assets/img/meter3.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import { AiOutlineTwitter } from "react-icons/ai";

export const Team = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="team">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Kayan Team</h2>
                        <p>WHY SO SERIOUS?</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>Popopopver</h5>
                                <h5>Criminal</h5>
                                <p>Do I look like the kind of clown that can start a movement?<br />Not clown-Joker.</p>
                                <a href="https://twitter.com/Apollordinals"><AiOutlineTwitter/></a>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image" />
                                <h5>Tammou</h5>
                                <h5>Clown</h5>
                                <p>Smile, because it confuses people.</p>
                                <a href="https://twitter.com/haruma4590"><AiOutlineTwitter/></a>             
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image" />
                                <h5>Sozu</h5>
                                <h5>Comedian</h5>
                                <p>I'm going to have a past,<br /> I prefer it to be multiple choice! Ha ha ha!</p>
                                <a href="https://twitter.com/0xSozu"><AiOutlineTwitter/></a>
                            </div>

                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
