import sneak1 from '../assets/img/sneak1.png'
import sneak2 from '../assets/img/sneak2.png'
import sneak3 from '../assets/img/sneak3.png'
import sneak4 from '../assets/img/sneak4.png'
import sneak5 from '../assets/img/sneak5.png'
import sneak6 from '../assets/img/sneak6.png'
import sneak7 from '../assets/img/sneak7.png'




export const Sneaks =() => {


    return(

        <div className="qabody" id="sneaks">
            <div className="shell" >
                        <div className="box" xs={12} md={6} xl={12}>
                            <img src={sneak1} alt="sneak1" />
                        </div>
                        <div className="box" xs={12} md={6} xl={12}>
                            <img src={sneak2} alt="sneak2" />
                        </div>
                        <div className="box" xs={12} md={6} xl={12}>
                            <img src={sneak3} alt="sneak3" />
                        </div>
                        <div className="box" xs={12} md={6} xl={12}>
                            <img src={sneak4} alt="sneak4" />
                        </div>
                        <div className="box" xs={12} md={6} xl={12}>
                            <img src={sneak5} alt="sneak5" />
                        </div>
                        <div className="box" xs={12} md={6} xl={12}>
                            <img src={sneak6} alt="sneak6" />
                        </div>
                        <div className="box" xs={12} md={6} xl={12}>
                            <img src={sneak7} alt="sneak7" />
                        </div>

            </div>
        </div>
    )
}