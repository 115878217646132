import { Container, Row, Col } from "react-bootstrap";
import headderImg from "../assets/img/header-img.png";

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={12}>
            <img src={headderImg} alt="Headder Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
